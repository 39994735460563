/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/purple-green.css';

html, body {
  margin: 0;
  padding: 0;
}

* {
  font-family: Roboto, sans-serif;
}

h3.module-header {
  font-size: 0.9rem;
  margin: 16px 0;
  padding: 10px;
  color: rgba(0, 0, 0, 0.54);
  text-transform: uppercase;
}

.marker {
  display: block;
  cursor: pointer;
  background-position: center;
  background-size: cover;
  border: 2px solid white;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.24);
  padding: 0;
}

.mat-card-header-text {
  margin: 0 3px !important;
}

.pointer {
  cursor: pointer;
}

button {
  outline: none !important;
}

.content-col {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.btn:focus, .btn:active {
  outline: none;
  box-shadow: none;
}

div:focus, div:active {
  outline: none;
  box-shadow: none;
}

